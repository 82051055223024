import { theme } from '../../../styles'

export interface IBanner {
  backgroundColor?: string
  children: React.ReactNode | string
  textColor?: string
}

export function Banner({ backgroundColor, children, textColor }: IBanner): JSX.Element {
  return (
    <>
      <div className="Banner flex justify-start items-center">{children}</div>

      <style jsx>{`
        .Banner {
          background: ${backgroundColor || theme.cssVars.deepBlue};
        }
      `}</style>
      <style jsx>{`
        .Banner {
          color: ${textColor || theme.cssVars.white};
        }
      `}</style>
      <style jsx>{`
        .Banner {
          height: 70px;
          width: 100%;
          padding: 20px 24px;
        }
      `}</style>
    </>
  )
}

import { useEffect, useState } from 'react'
import { Banner } from '../../atoms/Banner'
import { Icon } from '../../atoms/Icon'
import { mixins, theme } from '../../../styles'
import { isServer } from '../../../helpers/client'

export interface IOutdatedBanner {
  text: string
}

export function OutdatedBanner({ text }): JSX.Element {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!isServer()) setIsVisible(true)
  }, [])

  return (
    <>
      <Banner backgroundColor={isVisible ? theme.cssVars.infoBlue : theme.cssVars.deepBlue}>
        {isVisible ? (
          <div className="OutdatedBanner flex justify-between items-center">
            {text}
            <Icon
              name="info"
              primaryColor={theme.cssVars.white}
              secondaryColor={theme.cssVars.white}
            />
          </div>
        ) : null}
      </Banner>
      <style jsx>{`
        .OutdatedBanner {
          width: 100%;

          font-family: ${theme.cssVars.overpass};
          font-style: normal;
          font-size: 20px;
          font-weight: 800;
          line-height: 133%;
        }

        @media ${mixins.mediaQuery.tablet} {
          .OutdatedBanner {
            ${mixins.header.maxWidthDesktop}
          }
        }
      `}</style>
    </>
  )
}

import { OutdatedBanner, type IOutdatedBanner } from '../../molecules/OutdatedBanner'
import { useAmp } from 'next/amp'

import { mixins, theme } from '../../../styles'

export interface IArticleBanner {
  outdatedBanner?: IOutdatedBanner
}

export function ArticleBanner({ outdatedBanner }: IArticleBanner): JSX.Element {
  const isAmp = useAmp()

  if (!outdatedBanner || isAmp) {
    return null
  }

  return (
    <>
      <div className="ArticleBanner">
        <OutdatedBanner {...outdatedBanner} />
      </div>

      <style jsx>{`
        .ArticleBanner {
          width: 100%;
          position: sticky;
          z-index: 9;
          transition: transform 0.2s ease-in, top 0.2s ease-in;
        }

        .ArticleBanner :global(.Topic) {
          min-height: 64px;
        }

        @media ${mixins.mediaQuery.tablet} {
          .ArticleBanner :global(.Topic) {
            max-width: ${theme.layout.maxWidth}px;
            margin: auto;
          }
        }
      `}</style>
    </>
  )
}
